<template>
	<div class="jobhunting">
		<div class="login">
			<div class="contain">
				<img src="../assets/img/loginc.png" />
				<div class="el-card box-card login_body is-always-shadow" id="loginbox">
					<div class="right">
						<div>
							<div class="textIn">
								<div class="el-row card_input">
									<span class="input_label">用户名</span>
									<div class="el-input el-input--prefix">
										<input type="text" class="el-input__inner" v-model="subfrom.mobile"
											placeholder="请输入用户名" />
									</div>
								</div>
								<div class="el-row card_input">
									<span class="input_label">企业名称</span>
									<div class="el-input el-input--prefix">
										<input type="text" class="el-input__inner" v-model="subfrom.name"
											placeholder="企业名称" />
									</div>
								</div>
								<div class="el-row card_input">
									<span class="input_label">密码</span>
									<div class="el-input el-input--prefix">
										<input :type='pwdFlag?"password":"text"' class="el-input__inner"
											v-model="subfrom.password" placeholder="请输入密码"
											@input="pwStrength($event.target.value)" />
									</div>
								</div>
								<div class="tip">
									密码强度：
									<span id="strength_L">危险</span>
									<span id="strength_M">一般</span>
									<span id="strength_H">安全</span>
									<a @click="btnPass()">{{pwdFlag?"显示密码":"隐藏密码"}}
										<i class="el-icon-info"></i></a>
								</div>
								<div class="tip">
									密码必须包含数字、字母、符号
								</div>
								<div class="el-row card_input">
									<span class="input_label">确认密码</span>
									<div class="el-input el-input--prefix">
										<input type="password" class="el-input__inner" v-model="subfrom.repassword"
											placeholder="确认密码" />
									</div>
								</div>
								<div class="el-row card_input">
									<span class="input_label">验证码</span>
									<div class="el-input el-input--prefix">
										<input type="text" class="el-input__inner" v-model="subfrom.code"
											placeholder="请输入验证码" />
										<span id="canvasCode" class="code"></span>
									</div>
								</div>
								<div class="line">
								</div>
							</div>
							<div class="textIn">
								<div class="free">
									<el-checkbox v-model="checked">我已阅读并同意<b><a
												@click="goto('/info?id=12923')">《临海人才网用户服务协议》</a></b>
									</el-checkbox>
								</div>
								<div class="clear"></div>
								<div class="btn" @click="Registerusers()">注册</div>
								<div class="free">
									<a>忘记密码</a>
									<a @click="goto('/loginc')">登录账户</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import {
		UserLogin,
		CompanyLogin,
		Getscanuserinfo,
		Registerwx,
	} from "@/api/user";
	import {
		isnull
	} from "@/assets/js/index";
	import {
		getGVerify
	} from "@/api/home";
	import QRCode from "qrcodejs2";
	import global from "@/assets/js/globalconfig";
	import utils from "@/assets/js/utils";
	import { detectZoom } from '@/utils/detectZoom';
	const m = detectZoom();
	
	export default {
		data() {
			return {
				activeName: "second",
				api: global.baseurl,
				type: 2,
				sm: false,
				yzm: true,
				timer: null,
				state: "",
				pwdFlag: true,
				subfrom: {
					mobile: "",
					password: "",
				},
				isback: false,
				qrcode: new Array(),
				GVerifyCode: null,
				cbxbox: false
			};
		},
		created() {
			var query = this.$route.query;

			var _this = this;
			if (!isnull(query.type)) {
				_this.type = parseInt(query.type);
				_this.isback = query.isback;
			}
		},
		mounted() {
			//this.creatQrCode();
			this.subfrom.mobile = localStorage.getItem("mobile2");
			this.subfrom.password = localStorage.getItem("password2");
			this.getGVerify();
			document.getElementById('loginbox').style.zoom = 100 / Number(m);
		},
		beforeDestroy() {
			clearInterval(this.timer);
		},
		methods: {
			getGVerify() {
				this.GVerifyCode = getGVerify({
					id: "canvasCode",
					width: "100",
					height: "48",
					fontSize: "26",
					numSize: 4,
					type: "blend",
				});
			},
			btnPass() {
				this.pwdFlag = this.pwdFlag ? false : true;
			},
			pwStrength(e) {
				utils.pwStrength(e)
			},
			getTimestamp() {
				//把时间日期转成时间戳
				return new Date().getTime();
			},

			getuserinfo() {
				var _this = this;
				this.timer = setInterval(() => {
					_this.Getscanuserinfos();
				}, 3000);
			},
			Getscanuserinfos() {
				var _this = this;
				Getscanuserinfo({
					code: _this.state,
					types: _this.type
				}).then((res) => {
					if (res.success) {
						if (res.data.type === 1) {
							_this.$message({
								message: "扫码成功",
								type: "success",
							});
							//新用户
							clearInterval(_this.timer);
							localStorage.setItem("wxtoken", res.data.rcOpenId);
							_this.resolvewin("/register", {
								type: _this.type
							}, 1);
						} else if (res.data === -1) {
							//暂无扫码
						} else if (res.data.type === 0) {
							_this.$message({
								message: "扫码成功",
								type: "success",
							});
							clearInterval(_this.timer);
							_this.subfrom.mobile = res.data.user[0].coM_ACCOUNT;
							_this.subfrom.password = res.data.user[0].coM_PWD;
							_this.subfrom.type = _this.type;
							_this.logininfo();
							//老用户
						}
					}
				});
			},
			logininfo(ld) {
				var _this = this;
				var rdatas = new Array();
				if (_this.subfrom.type === 1) {
					rdatas = UserLogin(ld);
				} else {
					rdatas = CompanyLogin(ld);
				}
				rdatas.then((res) => {
					if (res.success) {
						this.$message({
							message: "登录成功",
							type: "success",
						});
						var rdata = res.data;
						rdata.user.userType = _this.subfrom.type;
						localStorage.setItem("token", rdata.token);
						localStorage.setItem("userinfo", JSON.stringify(rdata.user));
						setTimeout(_this.resolvewin("/", {}, 1), 500);
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			handleClick() {
				this.isDisabled = false;
				this.subfrom.mobile = "";
				this.subfrom.name = "";
				this.subfrom.password = "";
				this.subfrom.repassword = "";
			},
			smLOGIN: function() {
				this.sm = false;
				this.yzm = true;

				clearInterval(this.timer);
			},
			yzmLOGIN: function() {
				var _this = this;
				setTimeout(() => {
					_this.creatQrCode();
				}, 500);
				this.sm = true;
				this.yzm = false;
			},
			goto(url) {
				this.$router.push(url);
			},
			topage(e) {
				this.$router.push(e);
			},
			//注册
			Registerusers() {
				var msg = "";
				var _this = this;
				/*var cphone = utils.telVerify(_this.subfrom.mobile);
				if (!cphone.state) {
					msg = cphone.message;
				} else */
				if (isnull(_this.subfrom.mobile)) {
					msg = "请输入用户名";
				} else if (isnull(_this.subfrom.password)) {
					msg = "请输入密码";
				} else if (isnull(_this.subfrom.repassword)) {
					msg = "请输入确认密码";
				} else if (_this.subfrom.repassword != _this.subfrom.password) {
					msg = "两次密码不一致";
				} //else if (!_this.checked) {
				// 	msg = "请先确认用户协议";
				// }
				if (isnull(_this.subfrom.name)) {
					msg = "请输入企业名称";
				}
				var flag = this.GVerifyCode.validate(_this.subfrom.code);
				if (!flag) {
					msg = "验证码错误";
					this.GVerifyCode.refresh();
				}
				if (!isnull(msg)) {
					_this.$message({
						message: msg,
						type: "warning",
					});
					return false;
				}

				var rdata = new Array();
				rdata = Registerwx(_this.subfrom);
				/*if (_this.activeName === "first") {
				} else {
					var par = {
						name: _this.subfrom.name,
						mobile: _this.subfrom.mobile,
						password: _this.subfrom.password,
						unionid: _this.subfrom.unionid,
						type: _this.subfrom.type, // 1 个人 2 企业
					};
					rdata = Wxbinduser(par);
				}*/

				rdata.then((res) => {
					if (res.success) {
						var parr = {
							mobile: "",
							name: "",
							password: "",
							type: _this.subfrom.type,
						};
						if (_this.activeName == "first") {
							parr = {
								mobile: _this.subfrom.mobile,
								password: _this.subfrom.password,
								type: _this.subfrom.type,
							};
						} else {
							parr.mobile =
								_this.subfrom.type === 1 ?
								res.data.peR_ACCOUNT :
								res.data.coM_ACCOUNT;
							parr.password =
								_this.subfrom.type === 1 ? res.data.peR_PWD : res.data.coM_PWD;
						}
						_this.logininfo(parr);
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	// tab
	/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
		border: none;
		border-radius: 0;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
		border: none;
	}

	/deep/.el-tabs--card>.el-tabs__header {
		border: none;
		margin: 0;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav {
		width: 100%;
		display: flex;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item {
		height: 45px;
		text-align: center;
		box-sizing: border-box;
		border-bottom: 1px solid #fff;
		font-size: 18px;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
		color: #276bf2;
		border-bottom: 1px solid #276bf2;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item:hover {
		color: #276bf2;
	}

	// 内容
	.login {
		width: 100%;
		height: calc(100vh - 125px);
		background: #a1c6f0;
		display: flex;
		align-items: center;
		justify-content: center;

		.contain {
			width: 100%;
			position: relative;
			height: 100%;
		}

		.contain>img {
			position: absolute;
			top: 10%;
			left: 10%;
			max-width: 45%;
		}

		.login_body {
			z-index: 99;
			width: 408px;
			position: absolute;
			top: 10%;
			right: 10%;
			border-radius: 12px;
			min-height: 466px;
			box-sizing: border-box;

			.right {
				padding: 20px;
				box-sizing: border-box;
				position: relative;
				border-radius: 12px;

				.scanning {
					padding-top: 50px;

					.qrcode {
						margin-bottom: 20px;
						display: flex;
						justify-content: center;
						align-content: space-between;
					}

					.radio-group {
						margin-bottom: 10px;
					}

					font-size: 14px;
					color: #999;
					text-align: center;

					img {
						width: 215px;
						margin-top: 40px;
					}

					b {
						color: #276bf2;
					}

					.title {
						font-size: 20px;
						color: #333;
						margin-bottom: 20px;
					}
				}

				.mode {
					position: absolute;
					top: 6px;
					right: 6px;
					display: flex;
					align-items: center;
					z-index: 996;

					.modeText {
						width: 100px;
						height: 29px;
						color: #999999;
						font-size: 12px;
						line-height: 29px;
						text-align: center;
						margin-left: 15px;
						border-radius: 4px;
						position: relative;
						box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
					}

					img {
						width: 56px;
					}
				}

				.mode:hover {
					cursor: pointer;
				}

				.textIn {
					width: 100%;

					.card_input {
						margin-top: 25px;
						position: relative;


					}

					.tip {
						font-size: 12px;
						color: #898989;
						margin-top: 17px;

						span {
							margin: 0 5px;
							background-color: #eee;
							color: #010101;
							width: 56px;
							line-height: 20px;
							text-align: center;
							display: inline-block;
						}

						a {
							color: #0096FF;
							position: relative;

							i {
								position: absolute;
								right: -20px;
								top: 3px;
							}
						}
					}

					.el-input__inner {
						height: 48px;
					}

					.input_label {
						position: absolute;
						background-color: white;
						z-index: 20;
						left: 10px;
						top: -10px;
						font-size: 12px;
						display: block;
						padding: 0px 6px;
						color: #666;
						border: 1px solid white;
						border-radius: 10px;
					}

					.input_labal_color {
						color: #2a96ff
					}

					.el-input__suffix {
						position: absolute;
						height: 100%;
						right: 5px;
						top: 0;
						text-align: center;
						color: #b4b4b4;
						transition: all .3s;
						pointer-events: none;
					}

					.code {
						height: 48px;
						position: absolute;
						right: 0;
					}

					.primary_span {
						font-size: 14px;
						color: #666;
						display: inline-block;
						cursor: pointer;
						color: #2a96ff;
					}

					.btn {
						width: 100%;
						height: 40px;
						line-height: 40px;
						text-align: center;
						font-size: 14px;
						color: white;
						background: #0096FF;
						margin-top: 15px;
						border-radius: 4px;
						cursor: pointer;
					}

					.free {
						color: #999;
						font-size: 14px;
						margin-top: 15px;
						display: flex;
						justify-content: space-between;
						align-content: center;

						a {
							color: #467DF2
						}

						label {
							cursor: pointer;
						}

						label:hover {
							color: #467DF2
						}
					}

					.free:hover {
						cursor: pointer;
					}
				}

				.tips {
					width: 100%;
					font-size: 12px;
					color: #999;
					text-align: center;
					line-height: 2;
					margin-top: 20px;

					b {
						color: #276bf2;
					}
				}
			}
		}
	}
</style>
